import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/bootstrap.min.css';
import './css/style.css';
import Header from "./components/Header";
import Footer from "./components/Footer";

const RequestPlan = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [fullName, setFullName] = useState('');
    const [gasStationName, setGasStationName] = useState('');
    const [phone, setPhone] = useState('');
    const [numOfStations, setNumOfStations] = useState('');
    const [comment, setComment] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const isFullNameValid = fullName.length > 5;
        const isGasStationNameValid = gasStationName.trim() !== '';
        const isPhoneValid = phone.trim() !== '' && phone.length >= 10 && /^\d+$/.test(phone);
        setIsFormValid(isFullNameValid && isGasStationNameValid && isPhoneValid && isChecked);
    }, [fullName, gasStationName, phone, isChecked]);



    const handleCheckboxClick = () => {
        setIsChecked(!isChecked);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const createdDate = new Date().toISOString().slice(0, 19).replace('T', ' ');

        if (isFormValid) {
            const data = {
                fullName,
                gasStationName,
                phone,
                numOfStations,
                comment,
                createdDate,
            };

            const fetchData = fetch('https://myazs.online/webapi/public/recievedjoinrequest', {
                method: 'POST',
                mode: 'same-origin',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA==',
                },
                body: JSON.stringify(data),
            }).then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response;
            });

            const timeout = new Promise((_, reject) =>
                setTimeout(() => reject(new Error('Request timed out')), 20000)
            );

            Promise.race([fetchData, timeout])
                .then(response => {
                    if (response.ok) {
                        navigate('/successfullrequest#main-content');
                    } else {
                        console.error('Failed response:', response);
                        navigate('/error#main-content');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    navigate('/error');
                });
        }
    };

    return (
        <div>
            <Header />
            <div className="main-content RequestPlan" id={"RequestPlan"}>
                <div className="container" style={{ justifyContent: "center" }}>
                    <h1 style={{ color: "black", fontSize: "30px", paddingTop: "150px", textAlign: "center" }}>Заполните форму и нажмите отправить</h1>
                    <h1 style={{ color: "black", fontSize: "20px", textAlign: "center" }}>Мы свяжемся с вами в течении рабочего дня.</h1>

                    <div className="form-box">
                        <form className="form-container" onSubmit={handleSubmit}>
                            <div className="row">

                                <div className="col-lg-6 col-sm-12">
                                    <div className="input-container">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="ФИО"
                                            value={fullName}
                                            onChange={(e) => setFullName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-12">
                                    <div className="input-container">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Название АЗС"
                                            value={gasStationName}
                                            onChange={(e) => setGasStationName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="input-container">
                                        <select
                                            className="form-select"
                                            aria-label="Выберите количество АЗС"
                                            value={numOfStations}
                                            onChange={(e) => setNumOfStations(e.target.value)}
                                        >
                                            <option value="">Выберите количество АЗС</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="Больше 10 АЗС">Больше 10 АЗС</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12">
                                    <div className="input-container">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Телефон для связи"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-sm-12">
                                    <div className="input-container">
                                        <textarea
                                            className="form-control"
                                            rows="7"
                                            placeholder="Ваш комментарий"
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                        ></textarea>
                                    </div>

                                    <div id='checkboxblockRequest'>
                                        <input type="checkbox" onClick={handleCheckboxClick} />
                                        <p className="form-box-bottom">
                                            Нажимая отправить я соглашаюсь на обработку моих <a href="/datamanagment" target="_blank" rel="noopener noreferrer">персональных данных</a> а также с <a href="/useragreement" target="_blank" rel="noopener noreferrer">пользовательским соглашением</a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <button className="btn btn-primary submit" disabled={!isFormValid}>Отправить</button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default RequestPlan;