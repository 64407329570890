import React from 'react';

const Rating = (props) => {
    return (
        <div className={'classrating'} style={{backgroundColor: props.color}}>
            <div className={'RatingLabel'}>{props.row}</div>
            <div className={'RatingLabel'}>{props.name}</div>
            <div className={'RatingLabel'}>{props.value}</div>
        </div>
    );
};

export default Rating;