import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route, Navigate,} from "react-router-dom";
import Login from "./components/Login.jsx";
import EmployeeLK from "./components/Employeelk.jsx";
import LandingPage from "./LandingPage";
import Contacts from "./Contacts";
import Materials from "./Materials";
import About from "./About";
import Datamanagment from "./components/Datamanagment";
import RequestPlan from "./RequestPlan";
import Successfullrequest from "./components/Successfullrequest";
import Errorpage from "./components/ErrorPage";
import PureDatamanagment from "./components/PureDatamanagment";
import PureUserAgreement from "./components/PureUserAgreement";
import UserAgreement from "./components/UserAgreement";

const root = ReactDOM.createRoot(
    document.getElementById("root")
);

root.render(
    <BrowserRouter basename={"/"}>
        <Routes>
            <Route path="" element={<LandingPage />} />
            <Route path="lk" element={<EmployeeLK />} />
            <Route path="login" element={<Login />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="about" element={<About />} />
            <Route path="materials" element={<Materials />} />
            <Route path="datamanagment" element={<Datamanagment />} />
            <Route path="useragreement" element={<UserAgreement/>} />
            <Route path="requestplan" element={<RequestPlan />} />
            <Route path="successfullrequest" element={<Successfullrequest />} />
            <Route path="error" element={<Errorpage />} />
            <Route path="fullscreen-datamanagment" element={<PureDatamanagment />} />
            <Route path="fullscreen-useragreement" element={<PureUserAgreement />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    </BrowserRouter>
);

