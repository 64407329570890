import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import './css/bootstrap.min.css'; // Import your CSS file for styling
import './css/media.css'; // Import your CSS file for styling
import './css/style.css'; // Import your CSS file for styling
import './css/owl.carousel.min.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import page_head_vector_1 from "./images/page-head-vector-1.png";
import page_head_vector_2 from "./images/page-head-vector-2.png";
import page_head_vector_3 from "./images/page-head-vector-3.png";
import page_head_vector_4 from "./images/page-head-vector-4.png";

const About = () => {

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="wrapper">
            <Header/>


            <div className="main-content contact-sec">
                <section className="page-head" id="page-head-about">
                    <span>Кто мы такие</span>
                    <h2>О нас</h2>
                    <div className="page-vectors">
                        <img className="page-vector-1" src={page_head_vector_1} alt="page-vector" />
                        <img className="page-vector-2" src={page_head_vector_2} alt="page-vector" />
                        <img className="page-vector-3" src={page_head_vector_3} alt="page-vector" />
                        <img className="page-vector-4" src={page_head_vector_4} alt="page-vector" />
                    </div>
                </section>

                <div>
                        <div className="About-us-field">
                            <h3 id="section-title-about" style={{ textAlign: 'justify' }}>
                                Стремление улучшить бизнес-процессы в собственной сети АЗС
                                шаг за шагом привели к созданию Мой АЗС
                            </h3>
                            <div className="About-us-text">
                                    <p style={{ textIndent: '40px', textAlign: 'justify' }} >С детства я был вовлечен в семейный бизнес. Уже более 25 лет моя семья владеет небольшой сетью АЗС. Вместе с братом мы многие годы работаем над улучшением сервиса и внутренних бизнес-процессов на наших заправках.</p>
                                    <p style={{ textIndent: '40px', textAlign: 'justify' }}>В 2021 году мне пришла идея вывести остатки по нефтепродуктам на экран в нашем офисе. Я долго искал решение, разбираясь в устройстве программы Топаз АЗС. Когда я наконец понял, как всё работает, у меня появилась ещё лучшая идея: почему бы не сделать приложение для iOS, чтобы остатки всегда были под рукой? Я начал этим заниматься, и через несколько месяцев программа уже работала. Мы начали использовать её в нашей работе.</p>
                                    <p style={{ textIndent: '40px', textAlign: 'justify' }}>Позже, занимаясь рутинными проверками смен операторов, мне пришла в голову другая идея: добавить модуль смен, где, просто выбирая номера смен, программа будет автоматически выводить итоги для сверки. Это оказалось настолько удобным, что с тех пор на всех наших заправках мы используем именно эту функцию.</p>
                                    <p style={{ textIndent: '40px', textAlign: 'justify' }}>Со временем я стал собирать идеи других членов семьи о том, что ещё можно упростить, добавив в приложение. В итоге мы создали раздел зарплат, учитывающий особенности АЗС для расчета зарплат сотрудников. Мы также разработали отдельный веб-сайт для наших сотрудников, чтобы они могли в любое время зайти и посмотреть свою зарплатную ведомость. Сотрудники были очень довольны и пользуются этим сервисом по сей день.</p>
                                    <p style={{ textIndent: '40px', textAlign: 'justify' }}>Спустя некоторое время я добавил модуль статистики, чтобы мы могли быть в курсе текущей ситуации на АЗС. Это помогло нам предсказывать взлеты и падения спроса, а также планировать необходимое количество нефтепродуктов по результатам предыдущего дня.</p>
                                    <p style={{ textIndent: '40px', textAlign: 'justify' }}>Следующим модулем стал модуль талонов, так как некоторые наши клиенты предпочитают бумажные талоны топливным картам.</p>
                                    <p style={{ textIndent: '40px', textAlign: 'justify' }}>Однако оставалась одна сложная и времязатратная задача — работа с расчетным центром и топливными картами через Топаз Офис. Необходимость выполнять все операции через локальный компьютер в офисе усложняла процесс, особенно когда контрагенты могли позвонить в любое время с просьбой изменить лимит или зачислить средства. Я начал работать над интеграцией Топаз Офис в Мой АЗС. Спустя некоторое время у меня это получилось, и, исправляя ошибки в других разделах, я довел Мой АЗС до такого уровня, что оно стало неотъемлемой частью нашей работы.</p>
                                    <p style={{ textIndent: '40px', textAlign: 'justify' }}>Изначально я не планировал делать приложение коммерческим, но после нескольких лет разработки и тестирования на нашей сети АЗС мы решили, что Мой АЗС оказалось действительно полезным, и готовы предложить его другим.</p>
                                </div>

                        </div>

                </div>


            </div>

            <Footer/>



        </div>
    );
};

export default About;