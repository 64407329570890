import React from 'react';

const PrivacyPolicy = () => (
    <div>
        <h2>Политика конфиденциальности</h2>

        <h4>1. Общие положения</h4>
        <p>
            1.1. Настоящая политика конфиденциальности устанавливает порядок обработки и защиты персональных данных пользователей Сайта myazs.online и мобильного приложения «Мой АЗС» ИП Умаев Али Османович.
        </p>
        <p>
            1.2. Пользователь, подавая заявку на Сайте и используя Приложение, соглашается с настоящей политикой конфиденциальности.
        </p>

        <h4>2. Персональные данные</h4>
        <p>
            2.1. Персональные данные включают: ФИО, номер телефона, название АЗС, IP-адрес АЗС, имена сотрудников, их должности.
        </p>

        <h4>3. Цели обработки данных</h4>
        <p>
            3.1. Персональные данные обрабатываются для:
        </p>
        <ul style={{marginLeft: "40px", marginBottom: "20px"}}>
            <li>предоставления доступа к функционалу Сайта и Приложения;</li>
            <li>направления информационных и рекламных рассылок;</li>
            <li>улучшения работы Сайта и Приложения и качества услуг.</li>
        </ul>

        <h4>4. Защита персональных данных</h4>
        <p>
            4.1. Владелец обязуется принимать все необходимые меры для защиты персональных данных от несанкционированного доступа, изменения или раскрытия.
        </p>

        <h4>5. Права пользователя</h4>
        <p>
            5.1. Пользователь имеет право на:
        </p>
        <ul style={{marginLeft: "40px", marginBottom: "20px"}}>
            <li>доступ к своим персональным данным;</li>
            <li>исправление своих персональных данных в случае их неточности.</li>
        </ul>

        <h4>6. Условия обработки данных</h4>
        <p>
            6.1. Обработка персональных данных осуществляется в течение срока действия пользовательского соглашения.
        </p>
        <p>
            6.2. В случае удаления аккаунта пользователя, все его персональные данные будут безвозвратно удалены.
        </p>
    </div>
);

export default PrivacyPolicy;