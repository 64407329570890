import React, { useEffect, useState } from 'react';
import '../Styles/Employeelk.css';
import Competition from "./Competition";
import { useNavigate } from "react-router-dom";
import UserlogItem from "./userlogItem";
import Moment from 'moment';
import 'moment/locale/ru';

function Employeelk() {

    let navigate = useNavigate();
    Moment.locale('ru');

    const urlPath = 'https://myazs.online';
    const [isUserDataLoading, setIsUserDataLoading] = useState(false);
    const [isUserLogsLoading, setIsUserLogsLoading] = useState(false);
    const [Month, setMonth] = useState(Moment(new Date()).format("MMM YYYY"));
    const [CurrentMonthSelected, setCurrentMonthSelected] = useState(true);
    const [isUserSalaryDataLoading, setIsUserSalaryDataLoading] = useState(false);
    const [userData, setUserData] = useState({
        ID: '',
        Name: '',
        userPosition: '',
        TotalDebt: 0,
        AZS: '',
        OperatorID: '',
        isOnlyGas: 0,
        SalaryBySales: 1
    });
    const [userSalary, setUserSalary] = useState({
        Rate: 0.0,
        Litres: 0.0,
        Salary: 0.0,
        Bonus: 0.0,
        Prepayment: 0.0,
        Fees: 0.0,
        Minus: 0.0,
        Debt: 0.0,
        TotalSalary: 0.0
    });
    const [userLogs, setUserLogs] = useState([
        { Date_Created: '...', Payment_type: '...', Amount: 0, comment: '...' } // comment maximum 150 chars
    ]);

    useEffect(() => {
        getUserInfo();
        getUserStatistic(CurrentMonthSelected);
    }, [userData.Name, CurrentMonthSelected]);

    const getUserInfo = async () => {
        setIsUserDataLoading(true);

        const requestOptions = {
            method: 'GET',
            mode: 'same-origin',
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA=='
            }
        };

        const response = await fetch(`${urlPath}/webapi/secured/getuserinfo`, requestOptions);
        const data = await response.json();
        setUserData(data);
        setIsUserDataLoading(false);

        let element = document.getElementById('messageblock');

        if (element) {
            if (data.TotalDebt === 0 || data.TotalDebt === null) {
                element.style.visibility = 'hidden';
            } else {
                element.style.visibility = 'visible';
            }
        }
    };

    const getUserStatistic = async (isCurrentMonth) => {
        await getSalary(isCurrentMonth);
        await getUserLogs(isCurrentMonth);
    };

    const setButtonsState = async (monthIsCurrent) => {
        let previousMonth = document.getElementById('btn11');
        let thisMonth = document.getElementById('btn22');

        previousMonth.style.color = 'gray';
        previousMonth.style.border = 'solid gray';
        thisMonth.style.color = 'gray';
        thisMonth.style.border = 'solid gray';

        if (monthIsCurrent) {
            thisMonth.style.color = 'black';
            thisMonth.style.border = 'solid black';
        } else {
            previousMonth.style.color = 'black';
            previousMonth.style.border = 'solid black';
        }
    };

    const prevMonthClicked = async () => {
        await setButtonsState(false);
        setMonth(Moment(new Date()).subtract(1, 'months').format("MMM YYYY"));
        setUserSalary({
            Rate: 0.0,
            Litres: 0.0,
            Salary: 0.0,
            Bonus: 0.0,
            Prepayment: 0.0,
            Fees: 0.0,
            Minus: 0.0,
            Debt: 0.0,
            TotalSalary: 0.0
        });
        setUserLogs([
            { Date_Created: '...', Payment_type: '...', Amount: 0, comment: '...' }
        ]);
        setCurrentMonthSelected(false);
        getUserStatistic(false);
    };

    const currentMonthClicked = async () => {
        await setButtonsState(true);
        setMonth(Moment(new Date()).format("MMM YYYY"));
        setUserSalary({
            Rate: 0.0,
            Litres: 0.0,
            Salary: 0.0,
            Bonus: 0.0,
            Prepayment: 0.0,
            Fees: 0.0,
            Minus: 0.0,
            Debt: 0.0,
            TotalSalary: 0.0
        });
        setUserLogs([
            { Date_Created: '...', Payment_type: '...', Amount: 0, comment: '...' }
        ]);
        setCurrentMonthSelected(true);
        getUserStatistic(true);
    };

    const getSalary = async (isCurrentMonth) => {
        try {
            const requestOptions = {
                method: 'GET',
                mode: 'same-origin',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA=='
                }
            };

            const response = await fetch(`${urlPath}/webapi/secured/GetSalary?isCurrentMonth=${isCurrentMonth}`, requestOptions);
            if (response.status === 200) {
                let data = await response.json();
                setUserSalary(data);
                setIsUserSalaryDataLoading(false);
            }

        } catch (err) {
            console.log(err);
        }
    };

    const getUserLogs = async (isCurrentMonth) => {
        setIsUserLogsLoading(true);
        try {
            const requestOptions = {
                method: 'GET',
                mode: 'same-origin',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA=='
                }
            };

            const response = await fetch(`${urlPath}/webapi/secured/getUserLogs?isCurrentMonth=${isCurrentMonth}&manualDate=${false}`, requestOptions);
            if (response.status === 200) {
                let data = await response.json();
                setUserLogs(data);
                setIsUserLogsLoading(false);
            } else if (response.status === 404) {
                setIsUserLogsLoading(false);
            }

        } catch (err) {
            console.log(err);
        }
    };

    const signOut = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                mode: 'same-origin',
                credentials: "include",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA=='
                }
            };

            const response = await fetch(`${urlPath}/webapi/secured/logout`, requestOptions);

            if (!response.ok) {
                console.error('Logout failed:', response.statusText);
            } else {
                navigate("/login", { replace: true });
            }
        } catch (err) {
            console.error('Error during logout:', err);
        }
    };

    return (
        <div className='Home'>
            <b id='rusoil'>
                МОЙ АЗС
            </b>
            <div onLoadStart={getUserInfo} className='maindata'>
                <Competition />
                <div id={'exiteblock'}>
                    <button onClick={signOut} id={'exiteBtn'}>Выйти</button>
                </div>
                <div className='employeeData'>
                    <div id='employeeblock'>
                        <div className='employeestatic'>
                            Сотрудник:
                        </div>
                        <div className='employeeLabels'> {userData.Name}</div>
                    </div>
                    <div id='positionblock'>
                        <div className='employeestatic'>
                            Должность:
                        </div>
                        <div className='employeeLabels'>{userData.userPosition}</div>
                    </div>
                    {userSalary.Rate === 0 ? null : (
                        <div id='rateblock'>
                            <div className='employeestatic'>
                                Ставка:
                            </div>
                            <div id='ratelabel'>{userSalary.Rate} ₽/л</div>
                        </div>
                    )}
                    {userData.TotalDebt === 0 ? null : (
                        <div id='messageblock'>
                            <div id={'debtmessage'}>Долг за пред. месяц:</div>
                            <div id={'debtValue'}>{Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userData.TotalDebt)}</div>
                        </div>
                    )}
                </div>

                <div className='salaryData'>
                    <div className='datepicker'>
                        <div id='previousMonth'>
                            <button onClick={prevMonthClicked} type='button' className='btn01' id='btn11'>Прошлый месяц</button>
                        </div>
                        <div id='thisMonth'>
                            <button onClick={currentMonthClicked} type='button' className='btn02' id='btn22'>Текущий месяц</button>
                        </div>
                    </div>
                    <div id='month'>{Month}</div>
                    <div className='mainSalaryData'>
                        <div className='SalaryDataBlockStatic'>
                            <div className='mainSalaryStatic'>Литров продано:</div>
                            <div className='litrsSalaryBonusLabels'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'decimal', minimumFractionDigits: 0 }).format(userSalary.Litres)}</div>
                        </div>
                        <div className='SalaryDataBlockStatic'>
                            <div className='mainSalaryStatic'>Зарплата:</div>
                            <div className='litrsSalaryBonusLabels'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userSalary.Salary)}</div>
                        </div>
                        <div className='SalaryDataBlockStatic'>
                            <div className='mainSalaryStatic'>Премия:</div>
                            <div className='litrsSalaryBonusLabels'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userSalary.Bonus)}</div>
                        </div>
                        <div id='prepaymentblock'>
                            <div className='mainSalaryStatic'>Авансы:</div>
                            <div id='prepaymentlabel'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userSalary.Prepayment)}</div>
                        </div>
                        <div className='feeAndMinusBlock'>
                            <div className='mainSalaryStatic'>Штрафы:</div>
                            <div className='feeAndMinuslabels'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userSalary.Fees)}</div>
                        </div>
                        <div className='feeAndMinusBlock'>
                            <div className='mainSalaryStatic'>Минусы:</div>
                            <div className='feeAndMinuslabels'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userSalary.Minus)}</div>
                        </div>
                        <div id='debtblock'>
                            <div className='mainSalaryStatic'>Задолженность:</div>
                            <div id='debtlabel'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userSalary.Debt)}</div>
                        </div>
                        <div id='totalSalaryblock'>
                            <div id='totalSalary'>Итого:</div>
                            <div id='totalSalarylabel'>{isUserSalaryDataLoading ? 'загрузка' : Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', minimumFractionDigits: 0 }).format(userSalary.TotalSalary)}</div>
                        </div>
                    </div>
                </div>

                <div id={'UserLogsTitle'}>Записи за месяц:</div>

                {isUserLogsLoading ? <div>Загрузка ... </div> : userLogs.map((log, index) => <UserlogItem data={log} key={index} />)}
            </div>
        </div>
    );
}

export default Employeelk;