import React, {useEffect} from 'react';
import '../css/bootstrap.min.css'; // Import your CSS file for styling
import '../css/style.css';

import PrivacyPolicy from "./PrivacyPolicy"; // Import your CSS file for styling

const PureDatamanagment = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className="datamanagmentContainer">
                <div className="datamanagment">
                    <PrivacyPolicy />
                </div>
            </div>
        </div>
    );
};

export default PureDatamanagment;
