import React, {useState} from 'react';
import Rating from "./Rating";
const Competition = () => {

    const [Competition, setCompitition] = useState({
        Title: 'СОРЕВНОВАНИЕ',
        Condition: 'Продай больше всех литров и получи приз!',
        Reward: '1-е место - 5,000₽ \n2-е место - 3,000₽ \n3-е место 1,000₽',
        Rating:
            [{id: 'Ali-1', name: 'Умаев Али', value: 76000.0},
                {id: 'Maga-1', name: 'Умаев Магомед', value: 56000.0},
                {id: 'Улубиев Исмаил-1', name: 'Улубиев Исмаил', value: 46000.0}]

    });

    return (
        <div id='competition'>

            <div id='competitionTitle'>{Competition.Title}</div>
            <div id='competitionCondition'>{Competition.Condition}</div>
            <div id='competitionRewardTitle'>НАГРАДА</div>
            <div id='competitionRewardLabel'>{Competition.Reward}</div>
            <div id='competitionRatingTitle'>РЕЙТИНГ</div>
            <div className={'rating'}>

                <div id={'ratingNumber'}>
                    { Competition.Rating.map((rating, index) =>
                    <Rating name={rating.name} value={rating.value} key={rating.id} row = {index+1} color={index % 2 == 0 ? '#DEE0D3' : 'white'}/>
                    )}

            </div>

        </div>

    </div>
    );
};

export default Competition;