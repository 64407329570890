import React, {useEffect} from 'react';
import '../css/bootstrap.min.css'; // Import your CSS file for styling
import '../css/style.css';
import Footer from "./Footer";
import Header from "./Header";
import UserAgreementRules from "./UserAgreementRules"; // Import your CSS file for styling

const UserAgreement = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Header/>

            <div className="UserAgreementContainer">
                <div className="UserAgreement">
                    <UserAgreementRules/>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default UserAgreement;
