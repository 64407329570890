import React from 'react';

const UserAgreementRules = () => (
    <div>
        <h2>Пользовательское соглашение</h2>

        <h4>1. Общие положения</h4>
        <p>
            1.1. Настоящее пользовательское соглашение (далее – «Соглашение») регулирует отношения между вами, пользователем сайта myazs.online (далее – «Сайт») и мобильного приложения «Мой АЗС» (далее – «Приложение»), и владельцем Сайта и Приложения ИП Умаев Али Османович (далее – «Владелец»).
        </p>
        <p>
            1.2. Использование Сайта и Приложения означает ваше согласие с настоящим Соглашением.
        </p>
        <p>
            1.3. Владелец оставляет за собой право изменять данное Соглашение без какого-либо специального уведомления. Новая редакция Соглашения вступает в силу с момента ее размещения на Сайте и в Приложении, если иное не предусмотрено новой редакцией Соглашения.
        </p>
        <p>
            1.4. В случае несогласия Пользователя с какой-либо частью Соглашения, Пользователь обязан прекратить использование Сайта и Приложения.
        </p>

        <h4>2. Подача заявки на Сайте</h4>
        <p>
            2.1. Для использования всех возможностей Приложения Пользователю необходимо подать заявку на Сайте.
        </p>
        <p>
            2.2. При подаче заявки Пользователь обязуется предоставить точную и актуальную информацию, включая ФИО, название АЗС и количество АЗС. В случае изменения данных Пользователь обязуется своевременно обновлять информацию.
        </p>
        <p>
            2.3. После обработки заявки Пользователю будут предоставлены данные для входа в личный кабинет в Приложении.
        </p>

        <h4>3. Подписка и пробный период</h4>
        <p>
            3.1. Приложение «Мой АЗС» предоставляет доступ к своим функциям на основе подписки.
        </p>
        <p>
            3.2. Пользователю предоставляется пробный период длительностью одну неделю (7 дней) с момента активации подписки.
        </p>
        <p>
            3.3. По истечении пробного периода подписка автоматически продлевается на выбранный тарифный план, если не будет отменена пользователем.
        </p>
        <p>
            3.4. Пользователь имеет право отменить подписку в любой момент до окончания пробного периода без каких-либо затрат.
        </p>

        <h4>4. Права и обязанности сторон</h4>
        <p>
            4.1. Пользователь имеет право:
        </p>
        <ul>
            <li>использовать все доступные на Сайте и в Приложении услуги;</li>
            <li>задавать любые вопросы, относящиеся к услугам Сайта и Приложения;</li>
            <li>требовать от Владельца соблюдения условий настоящего Соглашения.</li>
        </ul>
        <p>
            4.2. Пользователь обязуется:
        </p>
        <ul style={{marginLeft: "40px", marginBottom: "20px"}}>
            <li>предоставлять достоверную информацию при подаче заявки на Сайте;</li>
            <li>не нарушать работу Сайта и Приложения;</li>
            <li>соблюдать авторские права и интеллектуальную собственность;</li>
            <li>не использовать Сайт и Приложение для распространения информации, противоречащей законодательству и нормам общественной морали;</li>
            <li>не использовать Сайт и Приложение для рассылки спама и нежелательной информации;</li>
            <li>соблюдать конфиденциальность учетных данных.</li>
        </ul>
        <p>
            4.3. Владелец обязуется:
        </p>
        <ul style={{marginLeft: "40px", marginBottom: "20px"}}>
            <li>обеспечивать работу Сайта и Приложения, за исключением случаев, когда это невозможно по техническим причинам;</li>
            <li>предоставлять пользователям информацию и техническую поддержку;</li>
            <li>защищать персональные данные пользователей в соответствии с Политикой конфиденциальности.</li>
        </ul>

        <h4>5. Ограничение ответственности</h4>
        <p>
            5.1. Владелец не несет ответственности за:
        </p>
        <ul style={{marginLeft: "40px", marginBottom: "20px"}}>
            <li>сбои в работе Сайта и Приложения, вызванные техническими неисправностями;</li>
            <li>действия Пользователей, нарушающие настоящее Соглашение;</li>
            <li>убытки, понесенные Пользователем в результате использования Сайта и Приложения.</li>
        </ul>
        <p>
            5.2. Владелец не гарантирует постоянную и бесперебойную работу Сайта и Приложения.
        </p>

        <h4>6. Условия использования данных</h4>
        <p>
            6.1. Пользователь предоставляет свое согласие на обработку своих персональных данных в соответствии с Политикой конфиденциальности.
        </p>
        <p>
            6.2. Владелец обязуется соблюдать конфиденциальность и не передавать данные третьим лицам без согласия Пользователя.
        </p>
        <p>
            6.3. Пользователь имеет право на доступ к своим персональным данным и их изменение.
        </p>

        <h4>7. Прочие условия</h4>
        <p>
            7.1. Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с действующим законодательством Российской Федерации.
        </p>
        <p>
            7.2. Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному исполнению не влечет недействительность иных положений Соглашения.
        </p>
    </div>
);

export default UserAgreementRules;