// Footer.js
import React, { useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import '../css/bootstrap.min.css';
import '../css/media.css';
import '../css/style.css';
import '../css/owl.carousel.min.css';
import MobileScreenshotcFooter from '../images/MobileScreenshotcFooter.png';
import app_store from "../images/app-store.png";
import logo from "../images/logo/logoFooter.png";
import AlertNotification from "./AlerNotif";
import alertpicture from "../images/app-store.png"

const Footer = () => {
    const [showAlert, setShowAlert] = useState(false);

    const handleScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const url = 'https://apps.apple.com/app/id1612851913'

    const redirectToAppStore = () => {
        window.open(url, '_blank');
    };

    const handleShowAlert = () => setShowAlert(true);
    const handleCloseAlert = () => setShowAlert(false);



    return (
        <div>
            <section className="try-onir">
                <div className="container">
                    <div className="try-content">
                        <div className="col-lg-5 col-sm-12">
                            <div className="try-onir-img">
                                <img src={MobileScreenshotcFooter} alt="МойАЗС" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-7 col-sm-12">
                                <h3 className="section-title">Протестируйте МойАЗС бесплатно!</h3>
                                <h6 className="section-subtitle">Неделя пробной подписки активируется после регистрации.</h6>
                                    <button className="ios-download trybtn" style={{borderRadius:"10px", backgroundColor:"white", width: "200px", height:"60px"}}>
                                        <div className="btn-container" onClick={redirectToAppStore}>
                                            <img src={app_store} alt="app-store" />
                                            <span>App Store</span>
                                        </div>
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-sm-12">
                            <div className="footer-start">
                                <img src={logo} alt="logo" />
                                <div><a href="#!">ИП Умаев Али Османович</a></div>
                                <div><a href="mailto:hello@onirapp.com">sales@myazs.online</a></div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="company">
                                <h5>Компания</h5>
                                <ul>
                                    <li><Link to="/about" onClick={handleScrollToTop}>О нас</Link></li>
                                    <li><Link to="/" onClick={handleScrollToTop}>Приложение</Link></li>
                                    <li><Link smooth to="/#pricing">Цена</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div>
                                <h5>Для справок</h5>
                                <ul>
                                    <li><Link to="/contacts" onClick={handleScrollToTop}>Контакты</Link></li>
                                    <li><Link to="/useragreement" onClick={handleScrollToTop}>Правила</Link></li>
                                    <li><Link to="/datamanagment" onClick={handleScrollToTop}>Обработка данных</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <div className="footer-socials">
                                <h6>Попробуй!</h6>
                                <div>

                                        <div className="ios-download2 trybtn2" >
                                            <button className="btn-container" onClick={redirectToAppStore}>
                                                <img src={app_store} alt="app-store" />
                                                <span>App Store</span>
                                            </button>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <AlertNotification
                show={showAlert}
                picture={alertpicture}
                title="Спасибо за ваш интерес к Мой АЗС."
                message="Приложение находится на финальном этапе разработки и скоро будет доступно в App Store. Свяжитесь с нами в разделе 'Контакты', и мы уведомим вас, как только приложение будет опубликовано."
                firstButtonTitle="Позже"
                onClose={handleCloseAlert}
                secondButtonTitle="Подать заявку"
                secondButtonLink="/requestplan"
            />
        </div>
    );
};

export default Footer;