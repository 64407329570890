import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../css/bootstrap.min.css'; // Import your CSS file for styling
import '../css/style.css';
import Header from "./Header";
import Footer from "./Footer";
import PrivacyPolicy from "./PrivacyPolicy"; // Import your CSS file for styling

const Datamanagment = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Header />

            <div className="datamanagmentContainer">
                <div className="datamanagment">
                    <PrivacyPolicy />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Datamanagment;
