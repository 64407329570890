import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import '../Styles/Login.css';

function Login() {

    const [userData, setUser] = useState({username: '', password:''})
    const [userMessage, setUserMessage] = useState('')

    let navigate = useNavigate()

    async function  SignupForm(event) {

        try {
            event.preventDefault()

            const requestOptions = {
                method: 'POST',
                mode: 'same-origin',//'cors',
                credentials: "include",
                headers: { 'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA=='},
                body: JSON.stringify({
                    username: userData.username,
                    password: userData.password
                })
            };

            const mypath = 'https://myazs.online'
            const response = await fetch(`${mypath}/webapi/public/authenticate`, requestOptions);
            const data = await response.json();


        if (data.LoggedIn === true) {
            setUserMessage('')


            navigate("/lk", { replace: true });



        } else {
                setUserMessage(data.status)
            }

        if (response.statusCode === 401) {setUserMessage(data.status)}

        } catch (err) {
            setUserMessage('Ошибка! Проверьте логин или пароль')
            console.log(err)
        }






    }

    function myFunction() {
        var x = document.getElementById("paswinput");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    return (
        <div className='Login'>
            <div className='logoBack'>
                <div className='logo'></div>
            </div>

            <div>
                <b className='msg'>{userMessage}</b>
            </div>

            <div className='form'>
                <form>
                    <div className='userInputsblock'>
                        <input type='text'
                               className='userinput'
                               placeholder='89282254545'
                               onChange={e => setUser({...userData, username: e.target.value})}
                        />
                    </div>
                    <div className='userInputsblock'>
                        <input type='password'
                               className='userinput'
                               placeholder='пароль'
                               id = 'paswinput'
                               onChange={e => setUser({...userData, password: e.target.value})}

                        />
                    </div>
                    <div className='checkboxblock'>
                        <input  type="checkbox" onClick={myFunction}/>
                        <b id= 'checkboxtext' > Показать пароль</b>
                    </div>

                </form>
            </div>
            <div>  <button onClick={ e => SignupForm(e)}
                           variant="outlined"
                           className='loginBtn'>
                ВОЙТИ
            </button>
            </div>



        </div>
    );
}

export default Login
