import React from 'react';
import Moment from 'moment';

const UserlogItem = (props) => {
    return (
        <div id={'MainLogBlock'}>
            <div id={'AmountAndCommentBlock'}>
                <div id={'AmountBlock'}>
                    <div id={'AmountType'}>{props.data.Payment_type}</div>
                    <div id={'AmountFromLogs'}>{props.data.Amount} ₽</div>
                </div>
                <div id={'CommentBlock'}>
                    {props.data.comment}
                </div>
            </div>

            <div id={'DateLogBlock'}>
              <div id={'dateitemLog'}> {Moment(props.data.Date_Created).format("DD-MM-YYYY HH:mm")}</div>
            </div>
        </div>
    );
};

export default UserlogItem;