import React, { useEffect, useState } from 'react';
import './css/bootstrap.min.css'; // Import your CSS file for styling
import './css/media.css'; // Import your CSS file for styling
import './css/style.css'; // Import your CSS file for styling
import './css/owl.carousel.min.css'; // Import your CSS file for styling
import Header from "./components/Header";
import Footer from "./components/Footer";
import FeatureModule from './components/FeatureModule'; // Импортируйте созданный компонент
import shiftManagementImage from './images/mobile-screenshot-hp2.png';
import icons8_gas_station from './images/icons8-gas_station.png';
import icons8_security_portal from './images/icons8-security_portal.png';
import work_phone_screenshot from './images/Phone Screenshot.png';
import icons8_push_notifications from './images/icons8-push_notifications.png';
import icons8_data_protection from './images/icons8-data_protection.png';
import featurenumber1 from "./images/featureNumber1.png"
import featurenumber2 from "./images/featureNumber2.png"
import featurenumber3 from "./images/featureNumber3.png"
import featurenumber4 from "./images/featureNumber4.png"
import featurenumber5 from "./images/featureNumber5.png"
import featurenumber6 from "./images/featureNumber6.png"
import featurenumber7 from "./images/featureNumber7.png"
import featurenumber8 from "./images/featureNumber8.png"
import featurenumber9 from "./images/featureNumber9.png"
import {useNavigate} from "react-router-dom";
import SubscriptionPlans from "./components/SubscriptionPlans";



const LandingPage = () => {

    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);



    const featureModules = [
        {
            image: featurenumber1,
            title: 'МОДУЛЬ ТОПЛИВО И ЦЕНЫ',
            subtitle: 'Удобный доступ к остаткам и перемещениям топлива',
            description: "Вы сможете следить за остатками, получать уведомления при низком уровне топлива, регулировать цены на все виды топлива, просматривать журнал поставок и осуществлять прием нефтепродуктов.",
            features: [
                'Отображение остатков топлива',
                'Отображение актуальных цен',
                'Информация об операторе на смене',
                'Прием и отгрузка нефтепродукта'
            ]
        },
        {
            image: featurenumber2,
            title: 'МОДУЛЬ СМЕНЫ',
            subtitle: 'Проверка смен стала удобной и быстрой',
            description: "Система позволяет проводить полную проверку смен только по номерам, что помогает выявлять и устранять недочеты. Мы упростили рутинный процесс проверки смен, сделав его максимально эффективным. Вы сможете выбирать, какие методы оплаты учитывать, а также выяснять, у кого был перелив.",
            features: [
                'Вывод итогов по сменам',
                'Оформление суммы в “Положено”',
                'Расчет перелива в рубли по актуальным ценам',
                'Поиск по заказам внутри смены',
                'Возможность проверять СБП через Тинькофф'
            ]
        },
        {
            image: featurenumber3,
            title: 'МОДУЛЬ СТАТИСТИКА',
            subtitle: 'Полная статистика по АЗС в одно нажатие',
            description: "Представьте, что у вас есть моментальный доступ ко всей статистике по АЗС. Вы всегда будете знать, какие были продажи вчера, чтобы лучше подготовиться к завтрашнему дню.",
            features: [
                'Гибкие настройки для вывода статистики',
                'Реализация топлива за вчерашний день',
                'Реализация & выручка по дням и месецам',
                'Статистика по выручке по методам оплат'
            ]
        },
        {
            image: featurenumber4,
            title: 'МОДУЛЬ ЗАРПЛАТЫ',
            subtitle: 'Расчитаем за вас зарплаты, штрафы, авансы и премии',
            description: "Вы с легкостью сможете рассчитывать зарплаты сотрудников, одним нажатием выписывать штрафы, премии и авансы. Также вы сможете просматривать историю зачислений и выводить все данные в удобном для печати формате.",
            features: [
                'Добавление новых сотрудников',
                'Учет зарплат, авансов,  штрафов и.т.д.',
                'Зарплата от реализации топлива',
                'Вывод в формате pdf'
            ]
        },
        {
            image: featurenumber5,
            title: 'ЛИЧНЫЙ КАБИНЕТ СОТРУДНИКА',
            subtitle: 'Сотрудники могут следить за своей зарплатой онлайн',
            description: "Все изменения в модуле “Зарплаты” отражаются в личном кабинете сотрудника. Таким образом, оператор всегда будет в курсе своей зарплаты, авансов, штрафов и других начислений. Это особенно удобно, когда зарплата оператора зависит от реализации топлива, так как программа рассчитывает её в реальном времени.",
            features: [
                'Выписка по зарплате сотрудника',
                'Возможность посмотреть за пред. месяц',
                'Расчет ЗП от реализации в реальном времени',
                'Список операций за месяц'
            ]
        },
        {
            image: featurenumber6,
            title: 'МОДУЛЬ КЛИЕНТЫ',
            subtitle: 'Работа с Топаз Офис никогда не была такой простой и быстрой',
            description: "Модуль “Клиенты” выводит работу с контрагентами на новый уровень. За пару кликов узнать баланс контрагента, пополнить или списать средства, а также вывести подробный отчет по операциям. А также  просматривать замороженные операции и разблокировать их.",
            features: [
                'Создание/ Изменение контрагентов',
                'Списание/ Пополнение счетов контрагентов',
                'Добавление топливных карт',
                'Управление лимитами и картами',
                'Вывод детальной статистики по операциям'
            ]
        },
        {
            image: featurenumber7,
            title: 'МОДУЛЬ ТАЛОНЫ',
            subtitle: 'Создавайте свои талоны: пару кликов — и они готовы к печати',
            description: "Укажите вид топлива, общий литраж и номиналы талонов. Наш алгоритм рассчитает количество талонов и распределит их на листах формата А4 для удобной печати. Количество талонов и их суммарный литраж всегда будут соответствовать вашим параметрам.",
            features: [
                'Генерация таланов',
                'Костомизация таланов',
                'Сохранение напечатанных таланов в истории',
                'Вывод в удобном для печати формате'
            ]
        },
        {
            image: featurenumber8,
            title: 'УРОВНИ ДОСТУПА',
            subtitle: 'Ограниченный доступ для других сотрудников',
            description: "Вы можете добавить сотрудников, назначить им должности и предоставить доступ к определённым разделам Мой АЗС. Права можно менять в любой момент. Вход для сотрудников осуществляется через Мой АЗС по номеру телефона, указанному владельцем АЗС в аккаунте сотрудника.",
            features: [
                'Добавление сотрудников с доступом',
                'Назначение должности',
                'Изменение прав сотрудников',
                'Мгновенная реализация всех изменений'
            ]
        },
        {
            image: featurenumber9,
            title: 'ПУШ-УВЕДОМЛЕНИЯ',
            subtitle: 'Уведомления об операциях и остатках',
            description: "Вы можете настроить уведомления, когда меняется цена на АЗС, производится перемещение топлива или остатки топлива достигают заданных минимальных значений. Настроить уведомления можно легко в разделе настроек.",
            features: [
                'Уведомления когда меняется цена на товливо',
                'Сообщим если остатки топлива станут критическими',
                'Уведомления о перемещениях топлива',
                'Удобная настройка уведомлений'
            ]
        }
        // Добавьте другие модули по аналогии
    ];


    const navigate = useNavigate();

    const url = 'https://apps.apple.com/app/id1612851913'

    const handleNavigation = () => {

        window.open(url, '_blank');

        // navigate('/requestplan#RequestPlan');
    };


    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => setIsMobile(window.innerWidth <= 768);

        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    return (
        <div className="wrapper">
            <Header/>
            <div className="main-content homepage-2">
                <section className="banner-section mainpage" id="home">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <h1>Выведи бизнес на новый уровень.</h1>

                                <div className="banner-buttons">
                                    <button className="get-started" onClick={handleNavigation}>
                                        Подключить сейчас
                                    </button>

                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="banner-img">
                                    <img src={shiftManagementImage} alt="Shift Management" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="how-it-works" id="how-works">
                    <div className="container">
                        <h3 className="section-title">Как работает Мой АЗС</h3>
                        <p className="section-subtitle">
                            Приложение подключается напрямую к Топаз АЗС и Топаз Офис, получая данные и выводя их в удобном для восприятия формате.
                            Для стабильной работы достаточно подключения к интернету через модем без статического IP или роутера.
                            Подключение АЗС или Офиса можно выполнить за несколько минут.
                        </p>
                        <div className="working-content">
                            <div className="row">
                                <div className="col-lg-4 col-sm-12">
                                    <div className="work-card in-touch">
                                        <div className="work-img">
                                            <img src={icons8_gas_station} alt="work-img" />
                                        </div>

                                        <h6 className="work-steps">Управление АЗС</h6>

                                        <p className="steps-para" style={{ textAlign: 'justify' }}>
                                            Получайте актуальные данные прямо с АЗС и управляйте ценами, топливом и контрагентами.
                                        </p>
                                    </div>
                                    <div className="work-card review">
                                        <div className="work-img">
                                            <img src={icons8_security_portal} alt="work-img" />
                                        </div>

                                        <h6 className="work-steps">Безопасная связь</h6>

                                        <p className="steps-para" style={{ textAlign: 'justify' }}>
                                            Вы можете подключить АЗС через наш VPN-сервер, что сделает связь защищенной и обеспечит работу даже при отсутствии статического IP или роутера.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div className="work-center"></div>
                                    <div className="work-center-img">
                                        <img src={work_phone_screenshot} alt="screenshots" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div className="work-card demo">
                                        <div className="work-img">
                                            <img src={icons8_data_protection} alt="work-img" />
                                        </div>

                                        <h6 className="work-steps">Доступ к данным</h6>

                                        <p className="steps-para" style={{ textAlign: 'justify' }}>
                                            Мы не храним данные с АЗС. Доступ к данным имеют только владелец АЗС и избранные им сотрудники.
                                        </p>
                                    </div>
                                    <div className="work-card setup">
                                        <div className="work-img">
                                            <img src={icons8_push_notifications} alt="work-img" />
                                        </div>

                                        <h6 className="work-steps">Пуш-уведомоления</h6>

                                        <p className="steps-para" style={{ textAlign: 'justify' }}>
                                            Если остатки топлива будут меньше заданных, вам будут приходить уведомления. Также уведомления будут отправляться при изменении цены, приеме или отгрузке топлива.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <b id="SmallTextIntheMiddle">*Поддерживается только Топаз АЗС и Топаз Офис. </b>
                    </div>

                </section>


                <section className="features" id="features">
                    <div className="container">
                        <h3 className="section-title">На что способен Мой АЗС</h3>
                        <p className="section-subtitle">
                            Управление автозаправочными станциями и выполнение рутинных задач станет намного проще и эффективнее.
                            Представляем вам систему "Мой АЗС", состоящую из семи модулей, предназначенных для эффективного управления и контроля на всех ваших заправках.
                        </p>

                        <div className="feature-content">
                            {featureModules.map((module, index) => (
                                <FeatureModule
                                    key={index}
                                    image={module.image}
                                    title={module.title}
                                    subtitle={module.subtitle}
                                    description={module.description}
                                    features={module.features}
                                    reverse={!isMobile && index % 2 === 1} // Меняем местами для четных элементов, если не на мобильном устройстве
                                />
                            ))}
                        </div>
                    </div>
                </section>



                <SubscriptionPlans/>



                <section className="faq">
                    <div className="container">
                        <h3 className="section-title">Часто задаваемые вопросы</h3>
                        <div className="qa-content">
                            <div className="question">Как оплачивается подписка?</div>
                            <div className="answer">
                                <p>Подписка оплачивается через само приложение. На главном экране в разделе “Настройки” можно произвести оплату через СБП.</p>
                            </div>
                        </div>

                        <div className="qa-content">
                            <div className="question">Как мне подключить свой АЗС?</div>
                            <div className="answer">
                                <p>После регистрации программа переведет вас на страницу добавления АЗС, где, следуя инструкции, вы сможете легко добавить свою АЗС.</p>
                            </div>
                        </div>

                        <div className="qa-content">
                            <div className="question">Будут ли данные с АЗС храниться в приложении или на вашем сервере?</div>
                            <div className="answer">
                                <p>Нет, данные с АЗС всегда загружаются напрямую с АЗС при запросе пользователя. Если на АЗС отключится интернет, данные будут недоступны.</p>
                            </div>
                        </div>

                        <div className="qa-content">
                            <div className="question">Могу я удалить аккаунт и все данные?</div>
                            <div className="answer">
                                <p>Да, в приложении “Мой АЗС” доступна кнопка удаления аккаунта. Это действие необратимо: все данные пользователя и сам аккаунт будут удалены.</p>
                            </div>
                        </div>

                        <div className="qa-content">
                            <div className="question">Как мой АЗС подключается к АЗС?</div>
                            <div className="answer">
                                <p>Есть два варианта подключения: ручная настройка и через клиент “Мой АЗС”. При настройке через клиент “Мой АЗС” статический IP не нужен, требуется только подключение к интернету.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer/>
            </div>

        </div>
    );
}

export default LandingPage;
