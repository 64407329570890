import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/bootstrap.min.css';
import './css/media.css';
import './css/style.css';
import './css/owl.carousel.min.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import page_head_vector_1 from "./images/page-head-vector-1.png";
import page_head_vector_2 from "./images/page-head-vector-2.png";
import page_head_vector_3 from "./images/page-head-vector-3.png";
import page_head_vector_4 from "./images/page-head-vector-4.png";

const Contacts = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [fullName, setFullName] = useState('');
    const [gasStationName, setGasStationName] = useState('');
    const [phone, setPhone] = useState('');
    const [numOfStations, setNumOfStations] = useState('');
    const [comment, setComment] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const isFullNameValid = fullName.length > 5;
        const isGasStationNameValid = gasStationName.trim() !== '';
        const isPhoneValid = phone.trim() !== '' && phone.length >= 10 && /^\d+$/.test(phone);
        setIsFormValid(isFullNameValid && isGasStationNameValid && isPhoneValid && isChecked);
    }, [fullName, gasStationName, phone, isChecked]);

    const handleCheckboxClick = () => {
        setIsChecked(!isChecked);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const createdDate = new Date().toISOString().slice(0, 19).replace('T', ' ');

        if (isFormValid) {
            const data = {
                fullName,
                gasStationName,
                phone,
                numOfStations,
                comment,
                createdDate,
            };

            const fetchData = fetch('https://myazs.online/webapi/public/recievedjoinrequest', {
                method: 'POST',
                mode: 'same-origin',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Basic aipfYXV0aF9mZiVnZyQ6XiVoZmo5SGRqJkBuaEoxMjMwVmJjc2NzYWZ1XiViRA==',
                },
                body: JSON.stringify(data),
            }).then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response;
            });

            const timeout = new Promise((_, reject) =>
                setTimeout(() => reject(new Error('Request timed out')), 20000)
            );

            Promise.race([fetchData, timeout])
                .then(response => {
                    if (response.ok) {
                        navigate('/successfullrequest#main-content');
                    } else {
                        console.error('Failed response:', response);
                        navigate('/error#main-content');
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    navigate('/error');
                });
        }
    };

    return (
        <div className="wrapper">
            <Header />

            <div className="main-content contact-sec">
                <section className="page-head" id="page-head-about">
                    <span>Мы на связи</span>
                    <h2>Контакты</h2>
                    <div className="page-vectors">
                        <img className="page-vector-1" src={page_head_vector_1} alt="page-vector" />
                        <img className="page-vector-2" src={page_head_vector_2} alt="page-vector" />
                        <img className="page-vector-3" src={page_head_vector_3} alt="page-vector" />
                        <img className="page-vector-4" src={page_head_vector_4} alt="page-vector" />
                    </div>
                </section>

                <div>
                    <div className="container">
                        <div className="form-box">
                            <form className="form-container" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="contant-info">
                                            <i className="fa-solid fa-location-dot"></i>
                                            <p>Республика Дагестан</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="contant-info">
                                            <i className="fa-solid fa-phone"></i>
                                            <p><b>+7(915) 005 8778</b> <br />
                                                <b>+7(985) 381 3676</b></p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="contant-info">
                                            <i className="fa-sharp fa-solid fa-envelope-open"></i>
                                            <p><b>sales@myazs.online</b> <br />
                                                <b>support@myazs.online</b> </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="input-container">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="ФИО"
                                                value={fullName}
                                                onChange={(e) => setFullName(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-12">
                                        <div className="input-container">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Название АЗС"
                                                value={gasStationName}
                                                onChange={(e) => setGasStationName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="input-container">
                                            <select
                                                className="form-select"
                                                aria-label="Выберите количество АЗС"
                                                value={numOfStations}
                                                onChange={(e) => setNumOfStations(e.target.value)}
                                            >
                                                <option value="">Выберите количество АЗС</option>
                                                <option value="1">1 АЗС</option>
                                                <option value="2">2 АЗС</option>
                                                <option value="3">3 АЗС</option>
                                                <option value="4">4 АЗС</option>
                                                <option value="5">5 АЗС</option>
                                                <option value="6">6 АЗС</option>
                                                <option value="7">7 АЗС</option>
                                                <option value="8">8 АЗС</option>
                                                <option value="9">9 АЗС</option>
                                                <option value="10">10 АЗС</option>
                                                <option value="Больше 10 АЗС">Больше 10 АЗС</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-sm-12">
                                        <div className="input-container">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Телефон для связи"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-sm-12">
                                        <div className="input-container">
                                            <textarea
                                                className="form-control"
                                                rows="7"
                                                placeholder="Ваш комментарий"
                                                value={comment}
                                                onChange={(e) => setComment(e.target.value)}
                                            ></textarea>
                                        </div>

                                        <div id='checkboxblockRequest'>
                                            <input type="checkbox" onClick={handleCheckboxClick} />
                                            <p className="form-box-bottom">
                                                Нажимая отправить я соглашаюсь на обработку моих <a href="/datamanagment" target="_blank" rel="noopener noreferrer">персональных данных</a> а также с <a href="/useragreement" target="_blank" rel="noopener noreferrer">пользовательским соглашением</a>.
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <button className="btn btn-primary submit" disabled={!isFormValid}>Отправить</button>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

            <Footer />
        </div>
    );
};

export default Contacts;