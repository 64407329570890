import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import '../css/bootstrap.min.css'; // Import your CSS file for styling
import '../css/media.css'; // Import your CSS file for styling
import '../css/style.css'; // Import your CSS file for styling
import '../css/owl.carousel.min.css';
import Header from "./Header";
import Footer from "./Footer"; // Import your CSS file for styling

import success_img from "../images/success_img.png";

const Successfullrequest = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="wrapper">
            <Header/>

            <div className="main-content success">
                <img src={success_img} alt="Success" style={{width: "100px", height: "100px", marginTop: "200px"}} />
                <a style={{color: "black", marginTop: "50px", textAlign: "center"}}>Спасибо! Ваша заявка была успешно отправлена.</a>
                <a style={{color: "black", textAlign: "center"}}>Мы скоро свяжемся с вами!</a>
            </div>

            <Footer/>
        </div>
    );
};

export default Successfullrequest;