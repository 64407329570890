import React, { useState, useEffect, useRef } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import '../css/bootstrap.min.css'; // Import your CSS file for styling
import '../css/media.css'; // Import your CSS file for styling
import '../css/style.css'; // Import your CSS file for styling
import '../css/owl.carousel.min.css'; // Import your CSS file for styling
import logo from '../images/logo/logo.png';

const Header = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const navRef = useRef();

    const handleScrollToTop = () => {
        window.scrollTo(0, 0);
        setIsMobileMenuOpen(false);
    };

    const presentNavMenuModally = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setIsMobileMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [navRef]);

    return (
        <header>
            <div className="container">
                <div className="nav" ref={navRef}>
                    <div className="Smalllogo">
                        <Link to="/" onClick={handleScrollToTop}>
                            <img src={logo} alt="Logo" />
                        </Link>
                    </div>

                    <div className="nav-mobile-menu" onClick={presentNavMenuModally}>
                        <span className="line line1"></span>
                        <span className="line line2"></span>
                        <span className="line line3"></span>
                    </div>


                    <nav className={`nav-menu ${isMobileMenuOpen ? 'active' : ''}`}>
                        <ul className="nav-links">
                            <li><Link smooth to="/#how-works" className="nav-link" onClick={presentNavMenuModally}>Как это работает</Link></li>
                            <li><Link smooth to="/#features" className="nav-link" onClick={presentNavMenuModally}>Возможности</Link></li>
                            <li id={'only-for-desktop'}><Link smooth to="/#pricing" className="nav-link" onClick={presentNavMenuModally}>Цена</Link></li>
                            <li><Link to="/materials" className="nav-link" onClick={handleScrollToTop}>Материалы</Link></li>
                            <li><Link to="/contacts" className="nav-link" onClick={handleScrollToTop}>Контакты</Link></li>
                            <li><Link to="/about" className="nav-link" onClick={handleScrollToTop}>О нас</Link></li>
                            <li id={'staff-Lk-mobile'}><Link smooth to="/#pricing" className="nav-link" onClick={presentNavMenuModally}>Цена</Link></li>
                            <li id={'staff-Lk-mobile'}><Link to="/login" className="nav-link" onClick={handleScrollToTop}>ЛК сотрудника</Link></li>

                        </ul>
                    </nav>
                    <div className={`nav-button ${isMobileMenuOpen ? 'active' : ''}`}>
                        <Link to="/login">
                            <button className="btn btn-primary">ЛК сотрудника</button>
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;