
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/AlertNotification.css';


const AlertNotification = ({ show, picture, title, message, firstButtonTitle, onClose, secondButtonTitle, secondButtonLink }) => {
    const navigate = useNavigate();

    if (!show) return null;

    const handleSecondButtonClick = () => {
        if (secondButtonLink) {
            navigate(secondButtonLink);
        }
    };

    return (
        <div className="alert-overlay">
            <div className="alert-container">
                <img src={picture} alt="Alert" className="alert-picture" />
                <h2 className="alert-title">{title}</h2>
                <p className="alert-message">{message}</p>
                <div className="alert-buttons">
                    <button className="alert-button" onClick={onClose}>{firstButtonTitle || 'OK'}</button>
                    {secondButtonTitle &&
                        <button className="alert-button black-button" onClick={handleSecondButtonClick}>{secondButtonTitle}</button>
                    }
                </div>
            </div>
        </div>
    );
};

export default AlertNotification;