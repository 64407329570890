import React, {useEffect} from 'react';
import '../css/bootstrap.min.css'; // Import your CSS file for styling
import '../css/style.css';
import Header from "./Header";
import Footer from "./Footer";
import error_img from "../images/error_img.png"; // Import your CSS file for styling

const Errorpage = () => {

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="wrapper">
            <Header/>

            <div className="main-content success">
                <img src={error_img} alt="Error" style={{width: "100px", height: "100px", marginTop: "200px"}} />
                <a style={{color: "black", marginTop: "50px", textAlign: "center"}}>Что-то пошло не так. Не удалось отправить запрос.</a>
                <a style={{color: "black", textAlign: "center"}}>Пожалуйста напишите к нам на почту sales@myazs.online или позаоните на номер +7988898988 </a>
            </div>

            <Footer/>
        </div>
    );
};

export default Errorpage;