import React from 'react';

const FeatureModule = ({ image, title, subtitle, description, features, reverse }) => {
    return (
        <div className={`row feature-module ${reverse ? 'reverse' : ''}`}>
            <div className="col-lg-5 col-sm-12">
                <div className="feature-img">
                    <img src={image} alt="screenshots" />
                </div>
            </div>
            <div className="col-lg-7 col-sm-12">
                <div className="feature-info">
                    <div className="title-top">{title}</div>
                    <h2 className="section-title section-title-h2">{subtitle}</h2>
                    <p className="section-subtitle">{description}</p>
                    <ul>
                        {features.map((feature, index) => (
                            <li key={index} className="feature-list">
                                <span>{feature}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default FeatureModule;