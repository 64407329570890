import React, { useEffect } from 'react';
import '../css/bootstrap.min.css'; // Import your CSS file for styling
import '../css/style.css';

import UserAgreementRules from "./UserAgreementRules"; // Import your CSS file for styling

const PureUserAgreement = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className="UserAgreementContainer">
                <div className="UserAgreement">
                    <UserAgreementRules/>
                </div>
            </div>
        </div>
    );
};

export default PureUserAgreement;